<template>
  <div class="frame" @click.native="openWindow('ResumeWindow')" style="z-index: 99; height: 100%; width: 100%;">
    <iframe src="https://donchiaqe.github.io/webamphost/" width="100%" height="98%" scrolling="no" ></iframe>
    <span v-if="$store.getters.getActiveWindow!='WinAmpWindow'" @click.native="openWindow('ResumeWindow')" style="bottom:0;left:0;width:100%;height:96.5%;position:absolute;" class="overlay"></span>
  </div>
</template>

<script>
import Webamp from "webamp";
export default {
  name: 'WinAmp',
  mounted() {
    const app = document.getElementById("winamp")
    const webamp = new Webamp();
    webamp.renderWhenReady(app)
  },
}
</script>

<style scoped>
/*-------------------------------------------*\
    CSS Normalisation
\*-------------------------------------------*/

iframe {
  overflow: hidden !important;
  border: none;
}

.frame {
  width: 100%;
  height: 97.5%;
}
</style>
