<template>
  <interact draggable :dragOption="dragOption" resizable :resizeOption="resizeOption" class="window window-style" :style="style" @dragmove="dragmove" @resizemove="resizemove" @click.native="setActiveWindow" :class="{ fullscreen: $store.getters.getWindowFullscreen(this.ComponentName), minimize: $store.getters.getWindowById(ComponentName).windowState=='minimize'}">
    <div class="top-bar-window" :class="$store.getters.getActiveWindow==this.ComponentName ? 'top-bar' : 'top-bar-deactivated'" id="top-bar" @dblclick="toggleWindowSize">
      <div class="window-name"><img class="icon-image" :src="require('@/assets/win95Icons/' + this.window.iconImage)" :alt="window.altText"/>{{this.window.displayName}}</div>
      <!-- <div class="window-name">{{this.window.displayName}}</div> -->
      <div class="triple-button">
        <button class="minimize-button button" @click="minimizeWindow">
                <span style="height: 2px; width: 6px; background: black; margin-top: 8px; margin-right: 2px;">
                </span>
        </button>
        <button class="close-button button" style="margin-right: 3px; padding-left: 1px;" @click="closeWindow">×</button>
      </div>
    </div>
    <div class="content">
      <div class="window-content" name="content">
        <div class="overlay">
          Background
        </div>
        <div class="preview-box" style="background-position: center; " :style="{ backgroundImage: 'url(' + $store.state.previewImageUrl + ')' }"></div>
        <div class="settings">
          <fieldset>
            <legend>Wallpaper</legend>
            <div class="list-container">
              <ul class="list">
                <li v-for="(item, index) in $store.state.backgrounds" :key="item" class="list-item" @click="onItemClick(index)">{{ item['name'] }}</li>
              </ul>
            </div>
          </fieldset>
        </div>
      </div>
      <div class="button-row">
        <button class="button-ok" @click="applyCloseWindow">OK</button>
        <button class="button-cancel" @click="closeWindow">Cancel</button>
        <button :disabled="this.$store.state.selectedBackgroundIndex == currentBackgroundIndex" :class="{'button-disable' : this.$store.state.selectedBackgroundIndex == currentBackgroundIndex}" class="button-apply" @click="applyBackground"><u>A</u>pply</button>
      </div>
    </div>
  </interact>
</template>

<style scoped>
/*-------------------------------------------*\
    Windows/Display
\*-------------------------------------------*/

.list-container {
  width: 100%;
  height: 100px;
  overflow-y: scroll;
  background-color: #c2c2c2;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list li {
  background-color: #ffffff;
  padding: 4px 0px 1px 5px;
  border-bottom: 1px solid #c2c2c2;
  font-size: small;
}

.list-item.active {
  background-color: rgb(0, 0, 118);
  color: white;
}

.preview-box{
  width: 250px;
  height: 250px;
  margin-bottom: 16px;
}

.button-row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  width: 100%;
}

.button-disable {
  pointer-events: none;
  text-shadow: 1px 1px white;
  color: rgb(132,132,132);
  opacity: 0.5;
}

.button-ok, .button-cancel {
  margin-right: 6px;
}

.button-ok, .button-apply, .button-cancel {
  height: 28px;
  background: rgb(195, 195, 195);
  border-top: solid rgb(250, 250, 250) 1px;
  border-left: solid rgb(250, 250, 250) 1px;
  border-right: solid rgb(90, 90, 90) 1px;
  border-bottom: solid rgb(90, 90, 90) 1px;
  box-shadow: 1px 1px black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  font-weight: bold;
  margin-left: 2px;
  width: 80px;
}

.overlay {
  position: absolute;
  top: -19px;
  left: -1px;
  font-size: 0.7rem;
  background: rgb(195, 195, 195);
  border-top: solid rgb(250, 250, 250) 1px;
  border-left: solid rgb(250, 250, 250) 1px;
  border-right: solid rgb(90, 90, 90) 1px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 5px;
  border-radius: 5px 5px 0 0;
}

.button-ok:active, .button-apply:active, .button-cancel:active {
  background: rgb(192, 192, 192);
  box-shadow: none;
  border-top: solid rgb(0, 0, 0) 1.5px;
  border-left: solid rgb(0, 0, 0) 1.5px;
  border-bottom: solid rgb(250, 250, 250) 1.5px;
  border-right: solid rgb(250, 250, 250) 1.5px;
}

.button-ok:hover, .button-apply:hover, .button-cancel:hover {
  cursor: pointer;
}

.settings {
  width: 100%;
}

fieldset {
  border-radius: 0px;
  background: rgb(192, 192, 192);
  border: rgb(137, 140, 143) 1.5px solid;
}

.window-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 100px 20px 100px;
  background: rgb(195, 195, 195);
  border-top: solid rgb(250, 250, 250) 1px;
  border-left: solid rgb(250, 250, 250) 1px;
  border-right: solid rgb(90, 90, 90) 1px;
  border-bottom: solid rgb(90, 90, 90) 1px;
  box-shadow: 1px 1px black;
}

.minimize {
  display: none;
}

.window {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  flex-flow: column;
  display: flex;
  max-width: 100vw !important;
  max-height: 100vh !important;
}

@media only screen and (max-width: 600px) {
  .window {
    min-height: 70vh;
  }
}

.content {
  flex-grow: 1;
  overflow-x: hidden;
  padding-right: var(--content-padding-right);
  padding-left: var(--content-padding-left);
  padding-top: var(--content-padding-top);
  padding-bottom: var(--content-padding-bottom);
  padding: 25px 10px 10px 10px;
}

/*-------------------------------------------*\
    Top Bar
\*-------------------------------------------*/

.top-bar {
  background: rgb(0, 0, 124);
}

.icon-image {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.top-bar-window {
  display: flex;
  width: auto;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  z-index: 10;
  margin: 2px;
  padding: 2px 2px 2px 2px;
}

.top-bar-deactivated {
  background: rgb(123, 125, 123);
}

.top-bar:hover {
  cursor: default;
}

.window-name {
  color: white;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 0;
  font-size: 16px;
  margin: 0 0 0 3px;
}

.icon-image {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  margin-top: 0;
  margin-bottom: 0;
}
</style>

<script>
import interact from "interactjs";
export default {
  props: {
    'windowId': String,
    'nameOfWindow': String,
    content_padding_left: {
      required: false,
      type: String,
      default: '15%',
    },
    content_padding_right: {
      required: false,
      type: String,
      default: '15%'
    },
    content_padding_top: {
      required: false,
      type: String,
      default: '5%'
    },
    content_padding_bottom: {
      required: false,
      type: String,
      default: '5%'
    },
  },
  data: function () {
    return {
      // ID Name of window (important)
      ComponentName: this.nameOfWindow,

      currentBackgroundIndex: 0,

      // window
      Window: {},

      dragOption: {
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: "#screen",
            endOnly: true
          })
        ],
        allowFrom: '#top-bar',
      },
      // values for interact.js transformation
      x: 0,
      y: 0,
      tempX: 0,
      tempY: 0,

    }
  },
  computed: {
    style() {
      return {
        height: `${this.h}px`,
        width: `${this.w}px`,
        transform: `translate(${this.x}px, ${this.y}px)`,
        '--content-padding-left': this.content_padding_left || '15%',
        '--content-padding-right': this.content_padding_right || '15%',
        '--content-padding-top': this.content_padding_top || '5%',
        '--content-padding-bottom': this.content_padding_bottom || '5%',
        '--fullscreen': this.$store.getters.getFullscreenWindowHeight
      };
    }
  },
  mounted() {
    const selectedBackgroundIndex = parseInt(localStorage.getItem("selectedBackgroundIndex"));
    if (!isNaN(selectedBackgroundIndex)) {
      this.currentBackgroundIndex = selectedBackgroundIndex;
      this.onItemClick(selectedBackgroundIndex);
    } else {
      this.currentBackgroundIndex = 0;
      this.onItemClick(0);
    }
  },
  created() {
    this.window = this.$store.getters.getWindowById(this.ComponentName)
  },
  methods: {
    onItemClick(index) {
      // Set the selected item index
      this.$store.commit('setSelectedBackgroundIndex', index);

      // Remove the "active" class from all list items
      const listItems = document.querySelectorAll('.list-item');
      listItems.forEach(item => {
        item.classList.remove('active');
      });

      // Add the "active" class to the clicked item
      const clickedItem = listItems[index];
      clickedItem.classList.add('active');
      const imageUrl = this.$store.state.backgrounds[this.$store.state.selectedBackgroundIndex]['image'];
      this.$store.commit('setPreviewImage', imageUrl);
    },

    applyCloseWindow() {
      this.$store.dispatch('applyBackground')
      this.closeWindow()
    },

    applyBackground() {
      this.currentBackgroundIndex = this.$store.state.selectedBackgroundIndex;
      this.$store.dispatch('applyBackground')
    },

    // functions to interact with window state

    closeWindow() {
      const payload = {
        'windowState': 'close',
        'windowID': this.ComponentName
      }
      this.$store.commit('setWindowState', payload)
    },

    openWindow() {
      const payload = {
        'windowState': 'open',
        'windowID': this.ComponentName
      }
      this.$store.commit('setWindowState', payload)
    },

    minimizeWindow() {
      const payload = {
        'windowState': 'minimize',
        'windowID': this.ComponentName
      }
      this.$store.commit('setWindowState', payload)
    },

    toggleWindowSize() {
      if (this.$store.getters.getWindowFullscreen(this.ComponentName) == true) {
        const payload = {
          'fullscreen': false,
          'windowID': this.ComponentName
        }
        this.$store.commit('setFullscreen', payload)
        this.x = this.tempX
        this.y = this.tempY

      } else if (this.$store.getters.getWindowFullscreen(this.ComponentName) == false) {
        const payload = {
          'fullscreen': true,
          'windowID': this.ComponentName
        }
        this.$store.commit('setFullscreen', payload)
        const tempX = this.x
        const tempY = this.y
        this.tempX = tempX
        this.tempY = tempY
        this.x = 0
        this.y = 0
      }
    },

    setActiveWindow() {
      this.$store.commit('zIndexIncrement', this.ComponentName)
      this.$store.commit('setActiveWindow', this.ComponentName)
    },

    // drag events

    dragmove(event) {
      this.x += event.dx;
      this.y += event.dy;
      this.setActiveWindow()
    },

    resizemove(event) {
      this.w = event.rect.width;
      this.h = event.rect.height;

      this.x += event.deltaRect.left;
      this.y += event.deltaRect.top;
    }
  }
}
</script>
