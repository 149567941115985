<template>
  <div>
<div id="app" >
<!--  <div id="overlay" :class="{'block-display' : password != '2023', 'none-display' : password == '2023'}">-->
<!--    <input style="z-index: 9999" type="password" v-model="password" placeholder="Password" />-->
<!--  </div>-->
<!--  <div v-if="windowCheck('WebAmpWindow')" id="WebAmpWindow"></div>-->
    <top-navbar id="top-navbar"></top-navbar>
    <div class="screen" id="screen" @click="deinitWindows">
        <div
            v-for="window in windows"
            :key="window.key"
            :aria-label="window.displayName"
        >
            <component
                :is="window.windowComponent"
                :nameOfWindow="window.windowId"
                :content_padding_bottom="window.windowContentPadding['bottom']"
                :content_padding_left="window.windowContentPadding['left']"
                :content_padding_right="window.windowContentPadding['right']"
                :content_padding_top="window.windowContentPadding['top']"
                :id="window.windowId"
                :style="{position: window.position, left: window.positionX, top: window.positionY}"
                v-if="windowCheck(window.windowId)"
            >
                <component
                    :is="window.windowContent"
                    slot="content"
                >
                </component>
            </component>
<!--          <div id="winamp-container"></div>-->

        </div>
        <app-grid id="app-grid"></app-grid>
    </div>
    <StartMenu v-if="$store.getters.getActiveWindow=='Menu'" style="position: absolute; z-index: 9999; left: 0; bottom: 39px"></StartMenu>
<!--  <div style="background: green; width: 50px; height: 50px;" @click="openWindow('WebAmpWindow')"></div>-->
    <navbar style="position: absolute; bottom: 0; z-index: 9999" id="navbar" />
</div>
  </div>
</template>

<style>
/*---------------------------------------------------------------------------*\
    CSS Imports
    Change 'blueprint' to 'windows' or 'macos' to use windows or macos theme
\*---------------------------------------------------------------------------*/
@import './assets/css/utils/normalize.css';
/* @import './assets/css/windows/app.css'; */
@import './assets/css/windows/window.css';
@import './assets/css/windows/appgrid.css';

@font-face {
    font-family: "MS Sans Serif";
    src: url('~@/assets/fonts/MS-Sans-Serif.ttf');
}

/*-------------------------------------------*\
    Utilities
\*-------------------------------------------*/

html {
    overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  //background: url("/background/sand.jpg");
  background-size:cover;
//background: rgb(231, 206, 168);
  //background-repeat: repeat;
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 9998; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

#overlay input {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  outline: none;
}

.block-display {
  display: flex;
}

.none-display {
  display: none;
}

#app {
    font-family: 'MS Sans Serif';
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    flex-direction: column;
}

#navbar {
  align-self: flex-end;
}

.screen {
    width: 100%;
    position: relative;
    z-index: 999;
}

::-webkit-scrollbar {
  width: 15px;
  background: repeating-conic-gradient(rgb(189, 190, 189) 0% 25%, rgb(255, 255, 255) 0% 50%)
              50% / 2px 2px;
}
::-webkit-scrollbar-thumb {
  background: rgb(189, 190, 189);
  /* box-shadow: 1.5px 1.5px black; */
    border-top: solid rgb(250, 250, 250) 1.5px;
    border-left: solid rgb(250, 250, 250) 1.5px;
    border-bottom: solid rgb(90, 90, 90) 1.5px;
    border-right: solid rgb(90, 90, 90) 1.5px;
    outline: rgb(219,219,219);
}

/*-------------------------------------------*\
    Fullscreen
\*-------------------------------------------*/

.fullscreen {
    left: 0 !important;
    position: relative;
    display: block;
    top: 0 !important;
    right: 0 !important;
}

html {
  cursor: url(https://cur.cursors-4u.net/others/oth-5/oth438.cur), auto !important;
}
</style>

<script>
/*------------------------------------------------------------*\
    Import Components, you can change the path for different
    component themes (blueprint, windows, macos)
\*------------------------------------------------------------*/
import Navbar from './components/windows/Navbar'
// import TopNavbar from './components/macos/TopNavbar.vue'
import Window from './components/template/Window'
import WinAmpWindow from "@/components/template/WinAmpWindow.vue";
import AppGrid from './components/AppGrid'
import About from './components/views/About'
import Atelier from './components/views/Atelier.vue'
import Photos from './components/views/Photos.vue'
import Shop from './components/views/Shop.vue'
import WinAmp from './components/views/WinAmp'
import Mail from './components/template/Mail'
import StartMenu from './components/StartMenu.vue'
import TelevisionWindow from "@/components/template/TelevisionWindow.vue";
import GameboyWindow from "@/components/template/GameboyWindow.vue";

import Gif1Window from "@/components/template/gifs/Gif1Window.vue";
import Gif2Window from "@/components/template/gifs/Gif2Window.vue";
import Gif3Window from "@/components/template/gifs/Gif3Window.vue";
import Gif4Window from "@/components/template/gifs/Gif4Window.vue";
import Gif5Window from "@/components/template/gifs/Gif5Window.vue";
import Gif6Window from "@/components/template/gifs/Gif6Window.vue";
import Gif7Window from "@/components/template/gifs/Gif7Window.vue";
import Gif8Window from "@/components/template/gifs/Gif8Window.vue";
import Gif9Window from "@/components/template/gifs/Gif9Window.vue";
import Gif10Window from "@/components/template/gifs/Gif10Window.vue";
import Gif11Window from "@/components/template/gifs/Gif11Window.vue";
import Gif12Window from "@/components/template/gifs/Gif12Window.vue";
import Gif13Window from "@/components/template/gifs/Gif13Window.vue";
import GameboyHelpWindow from "@/components/template/GameboyHelpWindow.vue";
import SettingsWindow from "@/components/template/SettingsWindow.vue";

import Webamp from "webamp";

export default {
    name: 'App',
    data: function () {
        return {
            windows: this.$store.getters.getWindows,
            windowComponents: {},
            password: ''
        }
    },
    components: {
        Window,
        WinAmpWindow,
        TelevisionWindow,
        GameboyWindow,
        GameboyHelpWindow,
        Gif1Window,
        Gif2Window,
        Gif3Window,
        Gif4Window,
        Gif5Window,
        Gif6Window,
        Gif7Window,
        Gif8Window,
        Gif9Window,
        Gif10Window,
        Gif11Window,
        Gif12Window,
        Gif13Window,
        SettingsWindow,
        Navbar,
        AppGrid,
        Mail,
        StartMenu,
        WinAmp,
        Shop,
        Photos,
        About,
        Atelier,
        // TopNavbar,
    },
    computed: {
        style() {
            return {
                '--fullscreen': this.$store.getters.getFullscreenWindowHeight
            };
        }
    },
    mounted() {
        /*-------------------------------------------------*\
            This fixes height problems for mobile devices 

            Code is detecting height of navbar and setting
            respective heights of screen
        \*-------------------------------------------------*/

        let navbar = document.getElementById('navbar')
        let topnavbar = document.getElementById('top-navbar')
        let topNavbarHeight = topnavbar.clientHeight
        let navbarHeight = navbar.clientHeight

        document.getElementById('screen').style.height = window.innerHeight - navbarHeight - topNavbarHeight + "px";

        window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });

        function resetHeight() {
            document.body.style.height = window.innerHeight + "px";
            document.html.style.height = window.innerHeight + "px";
        }
        window.addEventListener("resize", resetHeight);
        this.$store.commit('setFullscreenWindowHeight', window.innerHeight - navbarHeight - topNavbarHeight + "px");

        let deviceWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;

      // Retrieve the index of the previously selected background image from localStorage
        const selectedBackgroundIndex = parseInt(localStorage.getItem("selectedBackgroundIndex"));
        if (!isNaN(selectedBackgroundIndex)) {
          this.$store.commit('setBackgroundIndex', selectedBackgroundIndex)
          this.$store.commit("setSelectedBackgroundIndex", selectedBackgroundIndex);
          const imageUrl = this.$store.state.backgrounds[this.$store.state.selectedBackgroundIndex]['image'];
          this.$store.commit('setPreviewImage', imageUrl);
        } else {
          this.$store.commit('setBackgroundIndex', 7)
          this.$store.commit("setSelectedBackgroundIndex", 7);
          const imageUrl = this.$store.state.backgrounds[this.$store.state.selectedBackgroundIndex]['image'];
          this.$store.commit('setPreviewImage', imageUrl);
        }
        this.applyBackground()

       if (deviceWidth >= 1024) {
        setTimeout(() => {
            this.openWindow('Gif4Window')
          }, 200);
         setTimeout(() => {
           this.openWindow('Gif3Window')
         }, 400);
          setTimeout(() => {
            this.openWindow('Gif5Window')
          }, 600);
          setTimeout(() => {
          this.openWindow('Gif1Window')
        }, 800);
          setTimeout(() => {
            this.openWindow('AboutWindow')
          }, 1000);
        }
       else {
          this.openWindow('AboutWindow')
       }
    },
    methods: {
      applyBackground() {
        this.$store.dispatch('applyBackground')
      },
        openWindow(windowId) {
          if (windowId === 'WebAmpWindow') {
            setTimeout(function(){
              const webamp = new Webamp({

              });
              webamp.renderWhenReady(document.getElementById("WebAmpWindow"));
              console.log('webampwindow')

            }, 500);



          }
            const payload = {
                'windowState': 'open',
                'windowID': windowId
            }
            this.$store.commit('setWindowState', payload)
        },

        windowCheck(windowId) {
            if (this.$store.getters.getWindowById(windowId).windowState == 'open') {
                return true
            }
        },

        deinitWindows() {
            if (this.$store.getters.getActiveWindow=='Menu') {
                console.log('deinitWindows')
            this.$store.commit('setActiveWindow', '')
            setTimeout(() => {  
                this.$store.commit('zIndexIncrement', '')
                }, 0);
            }
        }
    },
}
</script>