<script>
import Client from "shopify-buy"
import ReadMore from "../ReadMore.vue"
export default {
  data: function() {
    return {
      products: []
    }
  },
  components: {
    ReadMore
  },
  mounted() {
    const client = Client.buildClient({
        domain: 'samples-catalogue.myshopify.com',
        storefrontAccessToken: '356f6007c98397e9a1d8ad9335009619'
      });

      client.product.fetchAll().then((products) => {
        this.products = products
        // console.log(products)
      });
  }
}
</script>

<template>
  <div :class="{'push-up' : products.length, 'flex' : !products.length}">
    <div v-if="!products.length">
      <img src="../../assets/loading.gif" width="160">
    </div>

    <div v-else :class="{ 'grid-expand' : this.$store.getters.getWindowFullscreen('ShopWindow')}" class="grid grid-small">
          <div class="box" v-for="product in products" :key="product.id">
            <a target="_blank" class="image-wrap" :href="product.onlineStoreUrl">
              <img class="image" v-for="image in product.images" :key="image.id" style="width: 100%;" :src="image.src" :alt="image.altText" />
            </a>
            <div class="text-wrap">
              <h3 style="margin-top: 15px;" class="limit">{{product.title}}</h3>
<!--              <h2><ReadMore class="paragraph" wordsLimit="50" :moreString="'<b>Name:</b> ' + product.title" :do-not-show-read-more=true /></h2>-->
              <ReadMore class="paragraph" wordsLimit=205 target="_blank" :link="product.onlineStoreUrl" :moreString="'<b>Description:</b> ' +product.description"/>
              <p class="paragraph"><b>Price:</b> ${{product.variants[0].price.amount}}</p>
              <a target="_blank" class="paragraph" style="margin-top: 15px;" :href="product.onlineStoreUrl"><b>Buy Now</b></a>
            </div>
          </div>
    </div>
  </div>
</template>

<style scoped>
.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.grid {
  display: grid;
  width: 100%;
  padding-bottom: 10px;
  justify-content: center;
  gap: 5px;
}

.grid-small {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-expand {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

@media (min-width: 768px) {
  .grid-small {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .grid-expand {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .grid-small {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .grid-expand {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (min-width: 1280px) {
  .grid-small {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .grid-expand {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.box {
  width: 100%;
  padding: 10px;
  background: rgb(192, 192, 192);
  box-shadow: none;
  border-top: solid rgb(0, 0, 0) 1.5px;
  border-left: solid rgb(0, 0, 0) 1.5px;
  border-bottom: solid rgb(250, 250, 250) 1.5px;
  border-right: solid rgb(250, 250, 250) 1.5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.push-up{
  align-items: start;
}

.image {
  width: 100%;
  object-fit: cover;
  height: 400px;
}

.image:hover {
  cursor: pointer;
  opacity: 0.7;
}

.text-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
}

h2, h4, h3 {
  padding: 0;
  margin: 0;
}

.paragraph {
  //overflow: hidden;
  //text-overflow: ellipsis;
  //display: -webkit-box;
  //-webkit-line-clamp: 4;
  //-webkit-box-orient: vertical;
}

.limit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
