import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // Height of Fullscreen Window
    fullscreenWindowHeight: window.innerHeight + "px",

    // Active Window State
    activeWindow: "BiographyWindow",

    // Active Windows Array State
    activeWindows: [],

    // Z-index State
    zIndex: 2, 

    windows: [
      {
        windowId: "AboutWindow", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "About", // Display Name (title under icon)
        windowComponent: 'window', // Window Component (can be changed to use modified windows)
        windowContent: 'about', // Window Content (used under slots)
        windowContentPadding: {
          top: null,
          right: null,
          bottom: null,
          left: null
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "6vw", // Window Position X (when first opened)
        positionY: "10%", // Window Position Y (when first opened)
        iconImage: "about.png", // Window Icon Image
        altText: "About", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInNavbar: true
      },
      {
        windowId: "ShopWindow", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "Shop", // Display Name (title under icon)
        windowComponent: 'window', // Window Component (can be changed to use modified windows)
        windowContent: 'shop', // Window Content (used under slots)
        windowContentPadding: {
          top: '5px',
          right: '5px',
          bottom: '5px',
          left: '5px'
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "9vw", // Window Position X (when first opened)
        positionY: "9%", // Window Position Y (when first opened)
        iconImage: "shop.png", // Window Icon Image
        altText: "Shop", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInNavbar: true
      },
      {
        windowId: "AtelierWindow", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "Atelier", // Display Name (title under icon)
        windowComponent: 'window', // Window Component (can be changed to use modified windows)
        windowContent: 'atelier', // Window Content (used under slots)
        windowContentPadding: {
          top: null,
          right: null,
          bottom: null,
          left: null
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "8vw", // Window Position X (when first opened)
        positionY: "8%", // Window Position Y (when first opened)
        iconImage: "world.png", // Window Icon Image
        altText: "Atelier", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInNavbar: true
      },
      {
        windowId: "MailWindow",
          windowState: "close",
          displayName: "Mail",
          windowComponent: 'mail',
          windowContent: '',
          windowContentPadding: {
            top: '0',
            right: '0',
            bottom: '0',
            left: '0'
          },
          position: "absolute",
          positionX: "6vw",
          positionY: "12vh",
          iconImage: "mail.png",
          altText: "Mail",
          fullscreen: false,
        showInNavbar: true
      },
      {
        windowId: "PhotosWindow", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "Content", // Display Name (title under icon)
        windowComponent: 'window', // Window Component (can be changed to use modified windows)
        windowContent: 'photos', // Window Content (used under slots)
        windowContentPadding: {
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "8vw", // Window Position X (when first opened)
        positionY: "20px", // Window Position Y (when first opened)
        iconImage: "photos.png", // Window Icon Image
        altText: "Photos", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInNavbar: true
      },
      {
        windowId: "WinAmpWindow", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "Media", // Display Name (title under icon)
        windowComponent: 'WinAmpWindow', // Window Component (can be changed to use modified windows)
        windowContent: 'WinAmp', // Window Content (used under slots)
        windowContentPadding: {
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "8vw", // Window Position X (when first opened)
        positionY: "8%", // Window Position Y (when first opened)
        iconImage: "winamp.png", // Window Icon Image
        altText: "WinAmp", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInNavbar: true
      },
      {
        windowId: "TelevisionWindow", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "TV", // Display Name (title under icon)
        windowComponent: 'TelevisionWindow', // Window Component (can be changed to use modified windows)
        windowContent: '', // Window Content (used under slots)
        windowContentPadding: {
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "8vw", // Window Position X (when first opened)
        positionY: "20px", // Window Position Y (when first opened)
        iconImage: "tv.png", // Window Icon Image
        altText: "Television", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInNavbar: true
      },
      {
        windowId: "GameboyWindow", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "Arcade", // Display Name (title under icon)
        windowComponent: 'GameboyWindow', // Window Component (can be changed to use modified windows)
        windowContent: '', // Window Content (used under slots)
        windowContentPadding: {
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "8vw", // Window Position X (when first opened)
        positionY: "20px", // Window Position Y (when first opened)
        iconImage: "gameboy.png", // Window Icon Image
        altText: "Arcade", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInNavbar: true
      },
      {
        windowId: "Gif1Window", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "", // Display Name (title under icon)
        windowComponent: 'Gif1Window', // Window Component (can be changed to use modified windows)
        windowContent: '', // Window Content (used under slots)
        windowContentPadding: {
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "58vw", // Window Position X (when first opened)
        positionY: "24%", // Window Position Y (when first opened)
        iconImage: "", // Window Icon Image
        altText: "", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInNavbar: false
      },
      {
        windowId: "Gif2Window", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "", // Display Name (title under icon)
        windowComponent: 'Gif2Window', // Window Component (can be changed to use modified windows)
        windowContent: '', // Window Content (used under slots)
        windowContentPadding: {
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "74vw", // Window Position X (when first opened)
        positionY: "28%", // Window Position Y (when first opened)
        iconImage: "", // Window Icon Image
        altText: "", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInNavbar: false
      },
      {
        windowId: "Gif3Window", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "", // Display Name (title under icon)
        windowComponent: 'Gif3Window', // Window Component (can be changed to use modified windows)
        windowContent: '', // Window Content (used under slots)
        windowContentPadding: {
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "59vw", // Window Position X (when first opened)
        positionY: "50%", // Window Position Y (when first opened)
        iconImage: "", // Window Icon Image
        altText: "", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInNavbar: false
      },
      {
        windowId: "Gif4Window", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "", // Display Name (title under icon)
        windowComponent: 'Gif4Window', // Window Component (can be changed to use modified windows)
        windowContent: '', // Window Content (used under slots)
        windowContentPadding: {
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "80vw", // Window Position X (when first opened)
        positionY: "65%", // Window Position Y (when first opened)
        iconImage: "", // Window Icon Image
        altText: "", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInNavbar: false
      },
      {
        windowId: "Gif5Window", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "", // Display Name (title under icon)
        windowComponent: 'Gif5Window', // Window Component (can be changed to use modified windows)
        windowContent: '', // Window Content (used under slots)
        windowContentPadding: {
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "70vw", // Window Position X (when first opened)
        positionY: "8%", // Window Position Y (when first opened)
        iconImage: "", // Window Icon Image
        altText: "", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInNavbar: false
      },
      {
        windowId: "Gif6Window", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "", // Display Name (title under icon)
        windowComponent: 'Gif6Window', // Window Component (can be changed to use modified windows)
        windowContent: '', // Window Content (used under slots)
        windowContentPadding: {
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "40vw", // Window Position X (when first opened)
        positionY: "20%", // Window Position Y (when first opened)
        iconImage: "", // Window Icon Image
        altText: "", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInNavbar: false
      },
      {
        windowId: "Gif7Window", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "", // Display Name (title under icon)
        windowComponent: 'Gif7Window', // Window Component (can be changed to use modified windows)
        windowContent: '', // Window Content (used under slots)
        windowContentPadding: {
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "30vw", // Window Position X (when first opened)
        positionY: "50%", // Window Position Y (when first opened)
        iconImage: "", // Window Icon Image
        altText: "", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInNavbar: false
      },
      {
        windowId: "Gif8Window", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "", // Display Name (title under icon)
        windowComponent: 'Gif8Window', // Window Component (can be changed to use modified windows)
        windowContent: '', // Window Content (used under slots)
        windowContentPadding: {
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "15vw", // Window Position X (when first opened)
        positionY: "40%", // Window Position Y (when first opened)
        iconImage: "", // Window Icon Image
        altText: "", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInNavbar: false
      },
      {
        windowId: "Gif9Window", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "", // Display Name (title under icon)
        windowComponent: 'Gif9Window', // Window Component (can be changed to use modified windows)
        windowContent: '', // Window Content (used under slots)
        windowContentPadding: {
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "18vw", // Window Position X (when first opened)
        positionY: "8%", // Window Position Y (when first opened)
        iconImage: "", // Window Icon Image
        altText: "", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInNavbar: false
      },
      {
        windowId: "Gif10Window", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "", // Display Name (title under icon)
        windowComponent: 'Gif10Window', // Window Component (can be changed to use modified windows)
        windowContent: '', // Window Content (used under slots)
        windowContentPadding: {
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "24vw", // Window Position X (when first opened)
        positionY: "70%", // Window Position Y (when first opened)
        iconImage: "", // Window Icon Image
        altText: "", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInNavbar: false
      },
      {
        windowId: "Gif11Window", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "", // Display Name (title under icon)
        windowComponent: 'Gif11Window', // Window Component (can be changed to use modified windows)
        windowContent: '', // Window Content (used under slots)
        windowContentPadding: {
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "60vw", // Window Position X (when first opened)
        positionY: "72%", // Window Position Y (when first opened)
        iconImage: "", // Window Icon Image
        altText: "", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInNavbar: false
      },
      {
        windowId: "Gif12Window", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "", // Display Name (title under icon)
        windowComponent: 'Gif12Window', // Window Component (can be changed to use modified windows)
        windowContent: '', // Window Content (used under slots)
        windowContentPadding: {
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "6vw", // Window Position X (when first opened)
        positionY: "70%", // Window Position Y (when first opened)
        iconImage: "", // Window Icon Image
        altText: "", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInNavbar: false
      },
      {
        windowId: "Gif13Window", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "", // Display Name (title under icon)
        windowComponent: 'Gif13Window', // Window Component (can be changed to use modified windows)
        windowContent: '', // Window Content (used under slots)
        windowContentPadding: {
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "8vw", // Window Position X (when first opened)
        positionY: "20%", // Window Position Y (when first opened)
        iconImage: "", // Window Icon Image
        altText: "", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInNavbar: false
      },
      {
        windowId: "GameboyHelpWindow", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "Help", // Display Name (title under icon)
        windowComponent: 'GameboyHelpWindow', // Window Component (can be changed to use modified windows)
        windowContent: '', // Window Content (used under slots)
        windowContentPadding: {
          top: '10px',
          right: '10px',
          bottom: '10px',
          left: '10px'
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "20vw", // Window Position X (when first opened)
        positionY: "30%", // Window Position Y (when first opened)
        iconImage: "", // Window Icon Image
        altText: "", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInNavbar: false
      },
      {
        windowId: "SettingsWindow", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "Settings", // Display Name (title under icon)
        windowComponent: 'SettingsWindow', // Window Component (can be changed to use modified windows)
        windowContent: '', // Window Content (used under slots)
        windowContentPadding: {
          top: '10px',
          right: '10px',
          bottom: '10px',
          left: '10px'
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "8vw", // Window Position X (when first opened)
        positionY: "20px", // Window Position Y (when first opened)
        iconImage: "settings.png", // Window Icon Image
        altText: "Settings", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInNavbar: true
      },
    ],
    mailContent: '',
    mailSender: '',
    mailSubject: 'New Message',

    // Settings
    backgrounds: [
      {
        name: 'Sand',
        image: 'background/sand.jpg'
      },
      {
        name: 'Teal',
        image: 'background/teal.jpg'
      },
      {
        name: 'Black',
        image: 'background/black.jpg'
      },
      {
        name: 'White',
        image: 'background/white.jpg'
      },
      {
        name: 'Mauve',
        image: 'background/mauve.jpg'
      },
      {
        name: 'Red',
        image: 'background/red.jpg'
      },
      {
        name: 'David',
        image: 'background/david.jpg'
      },
      {
        name: 'Charon',
        image: 'background/charon.jpg'
      },
      // {
      //   name: 'Michelangelo',
      //   image: 'background/michelangelo.jpg'
      // },
      {
        name: 'Lucio',
        image: 'background/lucio.jpg'
      },
      {
        name: 'Error',
        image: 'background/error.jpg'
      },
      {
        name: 'New York',
        image: 'background/newyork.jpg'
      },
      // {
      //   name: 'Rickenbacker',
      //   image: 'background/rickenbacker.jpg'
      // }
    ],

    previewImageUrl: "",

    selectedBackgroundIndex: 0,

    backgroundIndex: 0,
  },
  mutations: {
    // Active Window Mutator
    setActiveWindow(state, window) {
      console.log(window);
      state.activeWindow = window
    },

    // Pushes Active Window onto Active Windows Array
    pushActiveWindow(state, window) {
      if (window.showInNavbar == true) {
        state.activeWindows.push(window)
      }
    },

    // Removes Active Window from Active Windows Array
    popActiveWindow(state, window) {
      const windowIndex = state.activeWindows.indexOf(window);
      if (windowIndex !== -1) {
        state.activeWindows.splice(windowIndex, 1)
      }
    },

    // Z-index increment function
    zIndexIncrement(state, windowID) {
      state.zIndex += 1
      document.getElementById(windowID).style.zIndex = state.zIndex
    },

    // Set height of max-height of fullscreen window
    setFullscreenWindowHeight(state, height) {
      state.fullscreenWindowHeight = height
    },

    updateMail(state, local) {
      state.isShownMail = local
    },
    updateMailSubject(state, local) {
      state.mailSubject = local
    },
    updateMailContent(state, local) {
      state.mailContent = local
    },
    updateMailSender(state, local) {
      state.mailSender = local
    },

    setFullscreen(state, payload) {
      function getArrItem() {
        return state.windows.find(
          (windows) => windows.windowId === payload.windowID
        );
      }
      const window = getArrItem();
      window.fullscreen = payload.fullscreen;
    },

    // Window State Mutator
    setWindowState(state, payload) {
      // payload = {'windowState': 'open', 'windowID': 'WindowOne'}

      function getArrItem() {
        return state.windows.find(
          (windows) => windows.windowId === payload.windowID
        );
      }
      const window = getArrItem();

      var preventAppendingOpenWindow = false;
      if (window.windowState == "open" || window.windowState == "minimize") {
        preventAppendingOpenWindow = true;
      }

      if (payload.windowState == "open") {
        window.windowState = payload.windowState;
        setTimeout(() => {
          this.commit("zIndexIncrement", payload.windowID);
        }, 0);
        setTimeout(() => {
          this.commit("setActiveWindow", payload.windowID);
        }, 0);
        if (preventAppendingOpenWindow == false) {
          setTimeout(() => {
            this.commit("pushActiveWindow", window);
          }, 0);
        }
      } else if (payload.windowState == "close") {
        window.windowState = payload.windowState;
        setTimeout(() => {
          this.commit("popActiveWindow", window);
        }, 0);
        setTimeout(() => {
          this.commit("setActiveWindow", "nil"); 
        }, 0);
      } else if (payload.windowState == "minimize") {
        window.windowState = payload.windowState;
        this.commit("setActiveWindow", "nil"); 
      } else {
        console.log("Error: windowState not found or invalid");
      }
    },

    setPreviewImage(state, payload) {
      state.previewImageUrl = payload
    },

    setSelectedBackgroundIndex(state, payload) {
        state.selectedBackgroundIndex = payload
    },

    setBackgroundIndex(state, payload) {
        state.backgroundIndex = payload
    }
  },
  getters: {
    // Active Window Getter
    getActiveWindow: (state) => {
      return state.activeWindow;
    },

    // Window Getter
    getWindowById: (state) => (id) => {
      return state.windows.find((window) => window.windowId === id);
    },

    getWindowFullscreen: (state) => (id) => {
      return state.windows.find((window) => window.windowId === id).fullscreen;
    },

    getWindows: (state) => {
      return state.windows;
    },

    getActiveWindows(state) {
      return state.activeWindows;
    },

    getFullscreenWindowHeight(state) {
      return state.fullscreenWindowHeight;
    },

    mailContent: state => {
      return state.mailContent
    },

    mailSubject: state => {
      return state.mailSubject
    },

    mailSender: state => {
      return state.mailSender
    },
  },
    actions: {
      applyBackground() {
        // Get the selected image URL
        const selectedImage = this.state.backgrounds[this.state.selectedBackgroundIndex]['image'];

        // Set the background image of the site to the selected image
        document.body.style.backgroundImage = `url(${selectedImage})`;
        localStorage.setItem("selectedBackgroundIndex", this.state.selectedBackgroundIndex);
        this.state.backgroundIndex = this.state.selectedBackgroundIndex;
      },
    }
});
