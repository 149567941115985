<template>
  <interact draggable :dragOption="dragOption" resizable :resizeOption="resizeOption" class="window window-style" :style="style" @dragmove="dragmove" @resizemove="resizemove" @click.native="setActiveWindow" :class="{ fullscreen: $store.getters.getWindowFullscreen(this.ComponentName), minimize: $store.getters.getWindowById(ComponentName).windowState=='minimize'}">
    <div class="top-bar-window" :class="$store.getters.getActiveWindow==this.ComponentName ? 'top-bar' : 'top-bar-deactivated'" id="top-bar">
      <div class="window-name"><img class="icon-image" :src="require('@/assets/win95Icons/' + this.window.iconImage)" :alt="window.altText"/>{{this.window.displayName}}</div>
      <!-- <div class="window-name">{{this.window.displayName}}</div> -->
      <div class="triple-button">
        <button class="minimize-button button" @click="minimizeWindow">
                <span style="height: 2px; width: 6px; background: black; margin-top: 8px; margin-right: 2px;">
                </span>
        </button>
        <button class="close-button button" style="margin-right: 3px; padding-left: 1px;" @click="closeWindow">×</button>
      </div>
    </div>
    <div class="content">
      <div class="window-content" name="content">
        <div class="flex-col">
          <div class="navbar">
            <button class="remote" @click="loadGame('supermarioland')">Mario</button>
            <button class="remote" @click="loadGame('pokemonblue')">Pokemon</button>
            <button class="remote" @click="loadGame('pacman')">Pacman</button>
            <button class="remote" @click="loadGame('metroidii')">Metroid II</button>
            <div style="flex-grow: 1"></div>
            <button class="remote" @click="openWindow('GameboyHelpWindow')">Help</button>
          </div>
          <canvas id="canvas" width="160" height="144"></canvas>
        </div>
      </div>
    </div>
  </interact>
</template>

<style scoped>
/*-------------------------------------------*\
    Windows/Display
\*-------------------------------------------*/
canvas {
  width: 100%;
  image-rendering: pixelated;
}

.minimize {
  display: none;
}

.window {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  flex-flow: column;
  display: flex;
  height: auto !important;
}

.fullscreen {
  width: 100% !important;
  height: var(--fullscreen) !important;
  margin: 0;
  transition: all 0.5s ease;
  padding: 0;
}

.content {
  flex-grow: 1;
  overflow-x: hidden;
  padding-right: var(--content-padding-right);
  padding-left: var(--content-padding-left);
  padding-top: var(--content-padding-top);
  padding-bottom: var(--content-padding-bottom);
  overflow: hidden;
}

/*-------------------------------------------*\
    Top Bar
\*-------------------------------------------*/

.top-bar {
  background: rgb(0, 0, 124);
}

.icon-image {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.top-bar-window {
  display: flex;
  width: auto;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  z-index: 10;
  margin: 2px;
  padding: 2px 2px 2px 2px;
}

.top-bar-deactivated {
  background: rgb(123, 125, 123);
}

.top-bar:hover {
  cursor: default;
}

.window-name {
  color: white;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 0;
  font-size: 16px;
  margin: 0 0 0 3px;
}

.icon-image {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  margin-top: 0;
  margin-bottom: 0;
}

.navbar {
  padding: 6px;
  display: flex;
}

.remote {
  height: 28px;
  border-radius: 10px;
  margin-left: 2px;
  margin-right: 2px;
  box-shadow: 1.5px 1.5px black;
  border-top: solid rgb(250, 250, 250) 1.5px;
  border-left: solid rgb(250, 250, 250) 1.5px;
  border-bottom: solid rgb(90, 90, 90) 1.5px;
  border-right: solid rgb(90, 90, 90) 1.5px;
  background: rgb(192, 192, 192);
  border-radius: 0.5px;
  display: flex;
  justify-content: flex-start;
  align-items: center !important;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: bold;
  font-size: 0.7rem;
  padding-top: 2px;
}

.remote:active {
  background: rgb(192, 192, 192);
  box-shadow: none;
  border-top: solid rgb(0, 0, 0) 1.5px;
  border-left: solid rgb(0, 0, 0) 1.5px;
  border-bottom: solid rgb(250, 250, 250) 1.5px;
  border-right: solid rgb(250, 250, 250) 1.5px;
}

.remote:hover {
  cursor: pointer;
}

.flex-col {
  display: flex;
  flex-direction: column;
}
</style>

<script>
import interact from "interactjs";
import { WasmBoy } from 'wasmboy'
import Webamp from "webamp";
export default {
  props: {
    'windowId': String,
    'nameOfWindow': String,
    content_padding_left: {
      required: false,
      type: String,
      default: '15%',
    },
    content_padding_right: {
      required: false,
      type: String,
      default: '15%'
    },
    content_padding_top: {
      required: false,
      type: String,
      default: '5%'
    },
    content_padding_bottom: {
      required: false,
      type: String,
      default: '5%'
    },
  },
  data: function () {
    return {
      // ID Name of window (important)
      ComponentName: this.nameOfWindow,

      // window
      Window: {},

      // InteractJS states and modifiers
      dragOption: {
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: "#screen",
            endOnly: true
          })
        ],
        allowFrom: '#top-bar',
      },
      // values for interact.js transformation
      x: 0,
      y: 0,
      tempX: 0,
      tempY: 0,
      w: 400,
      h: 387,

    }
  },
  mounted () {

  },
  computed: {
    style() {
      return {
        height: `${this.h}px`,
        width: `${this.w}px`,
        transform: `translate(${this.x}px, ${this.y}px)`,
        '--content-padding-left': this.content_padding_left || '15%',
        '--content-padding-right': this.content_padding_right || '15%',
        '--content-padding-top': this.content_padding_top || '5%',
        '--content-padding-bottom': this.content_padding_bottom || '5%',
        '--fullscreen': this.$store.getters.getFullscreenWindowHeight
      };
    }
  },
  created() {
    this.window = this.$store.getters.getWindowById(this.ComponentName)
  },
  methods: {
    // functions to interact with window state
    loadGame(game) {
      const canvasElement = document.querySelector('canvas');

      const WasmBoyOptions = {
        headless: false,
        useGbcWhenOptional: true,
        isAudioEnabled: true,
        frameSkip: 1,
        audioBatchProcessing: true,
        timersBatchProcessing: false,
        audioAccumulateSamples: true,
        graphicsBatchProcessing: false,
        graphicsDisableScanlineRendering: false,
        tileRendering: true,
        tileCaching: true,
        gameboyFPSCap: 60,
        updateGraphicsCallback: false,
        updateAudioCallback: false,
        saveStateCallback: false,
        onReady: false,
        onPlay: false,
        onPause: false,
        onLoadedAndStarted: false
      }

      const inputState = {
        ButtonUp: false,
        ButtonDown: false,
        ButtonLeft: false,
        ButtonRight: false,
        ButtonA: false,
        ButtonB: false,
        ButtonStart: false,
        ButtonSelect: false
      };

// Add event listeners for key presses and releases
      document.addEventListener('keydown', event => {
        switch (event.code) {
          case 'ArrowUp':
            inputState.ButtonUp = true;
            break;
          case 'ArrowDown':
            inputState.ButtonDown = true;
            break;
          case 'ArrowLeft':
            inputState.ButtonLeft = true;
            break;
          case 'ArrowRight':
            inputState.ButtonRight = true;
            break;
          case 'KeyZ':
            inputState.ButtonA = true;
            break;
          case 'KeyX':
            inputState.ButtonB = true;
            break;
          case 'Enter':
            inputState.ButtonStart = true;
            break;
          case 'ShiftLeft':
            inputState.ButtonSelect = true;
            break;
        }
        updateInputState();
      });

      document.addEventListener('keyup', event => {
        switch (event.code) {
          case 'ArrowUp':
            inputState.ButtonUp = false;
            break;
          case 'ArrowDown':
            inputState.ButtonDown = false;
            break;
          case 'ArrowLeft':
            inputState.ButtonLeft = false;
            break;
          case 'ArrowRight':
            inputState.ButtonRight = false;
            break;
          case 'KeyZ':
            inputState.ButtonA = false;
            break;
          case 'KeyX':
            inputState.ButtonB = false;
            break;
          case 'Enter':
            inputState.ButtonStart = false;
            break;
          case 'ShiftLeft':
            inputState.ButtonSelect = false;
            break;
        }
        updateInputState();
      });

      function updateInputState() {
        // Update the input state in the emulator
        WasmBoy.setInputButton('ButtonUp', inputState.ButtonUp);
        WasmBoy.setInputButton('ButtonDown', inputState.ButtonDown);
        WasmBoy.setInputButton('ButtonLeft', inputState.ButtonLeft);
        WasmBoy.setInputButton('ButtonRight', inputState.ButtonRight);
        WasmBoy.setInputButton('ButtonA', inputState.ButtonA);
        WasmBoy.setInputButton('ButtonB', inputState.ButtonB);
        WasmBoy.setInputButton('ButtonStart', inputState.ButtonStart);
        WasmBoy.setInputButton('ButtonSelect', inputState.ButtonSelect);
      }
      WasmBoy.config(WasmBoyOptions, canvasElement).then(() => {
        // You may now load games, or use other exported functions of the lib.
        WasmBoy.loadROM('/files/' + game + '.gb').then(() => {
          WasmBoy.play().then(() => {
            WasmBoy.getCanvas()
          }).catch(() => {
            console.error('WasmBoy had an error playing...');
          });
        }).catch(() => {
          console.error('WasmBoy had an error loading the ROM...');
        });
      }).catch(() => {
        console.error('Error Configuring WasmBoy...');
      });
    },
    loadMario() {

    },
    closeWindow() {
      const payload = {
        'windowState': 'close',
        'windowID': this.ComponentName
      }
      this.$store.commit('setWindowState', payload)
    },

    openWindow(windowId) {
      if (windowId === 'WebAmpWindow') {
        setTimeout(function(){
          const webamp = new Webamp({

          });
          webamp.renderWhenReady(document.getElementById("WebAmpWindow"));
          console.log('webampwindow')

        }, 500);



      }
      const payload = {
        'windowState': 'open',
        'windowID': windowId
      }
      this.$store.commit('setWindowState', payload)
    },

    minimizeWindow() {
      const payload = {
        'windowState': 'minimize',
        'windowID': this.ComponentName
      }
      this.$store.commit('setWindowState', payload)
    },

    toggleWindowSize() {
      if (this.$store.getters.getWindowFullscreen(this.ComponentName) == true) {
        const payload = {
          'fullscreen': false,
          'windowID': this.ComponentName
        }
        this.$store.commit('setFullscreen', payload)
        this.x = this.tempX
        this.y = this.tempY

      } else if (this.$store.getters.getWindowFullscreen(this.ComponentName) == false) {
        const payload = {
          'fullscreen': true,
          'windowID': this.ComponentName
        }
        this.$store.commit('setFullscreen', payload)
        const tempX = this.x
        const tempY = this.y
        this.tempX = tempX
        this.tempY = tempY
        this.x = 0
        this.y = 0
      }
    },

    setActiveWindow() {
      this.$store.commit('zIndexIncrement', this.ComponentName)
      this.$store.commit('setActiveWindow', this.ComponentName)
    },

    // drag events

    dragmove(event) {
      this.x += event.dx;
      this.y += event.dy;
      this.setActiveWindow()
    },

    resizemove(event) {
      this.w = event.rect.width;
      this.h = event.rect.height;

      this.x += event.deltaRect.left;
      this.y += event.deltaRect.top;
    }
  }
}
</script>
