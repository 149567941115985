<template>
  <interact draggable :dragOption="dragOption" resizable :resizeOption="resizeOption" class="window window-style" :style="style" @dragmove="dragmove" @resizemove="resizemove" @click.native="setActiveWindow" :class="{ fullscreen: $store.getters.getWindowFullscreen(this.ComponentName), minimize: $store.getters.getWindowById(ComponentName).windowState=='minimize'}">
    <div class="top-bar-window" :class="$store.getters.getActiveWindow==this.ComponentName ? 'top-bar' : 'top-bar-deactivated'" id="top-bar">
      <div class="window-name"><img class="icon-image" :src="require('@/assets/win95Icons/' + this.window.iconImage)" :alt="window.altText"/>{{this.window.displayName}}</div>
      <!-- <div class="window-name">{{this.window.displayName}}</div> -->
      <div class="triple-button">
        <button class="minimize-button button" @click="minimizeWindow">
                <span style="height: 2px; width: 6px; background: black; margin-top: 8px; margin-right: 2px;">
                </span>
        </button>
        <button class="close-button button" style="margin-right: 3px; padding-left: 1px;" @click="closeWindow">×</button>
      </div>
    </div>
    <div class="content">
      <div class="window-content" name="content">
        <div class="flex-col">
          <div class="navbar">
            <button class="remote" v-click-async="nextChannel">Change Channel</button>
<!--            {{currentChannel}}-->
            <button class="remote" @click="mute">{{ muted ? 'Unmute' : 'Mute'}}</button>
          </div>
          <video v-if="showStatic" :class="{block : showStatic, hidden : !showStatic}" :key="currentChannel" autoplay loop>
            <source src="/files/videos/0vid.mp4" >
          </video>
          <video id="video-player" preload="auto" :class="{block : !showStatic, hidden : showStatic}" :key="channel" autoplay @ended="nextChannel">
            <source  :src="currentChannel" type="video/mp4">
          </video>
        </div>
      </div>
    </div>
  </interact>
</template>

<style scoped>
video {
  //width: 100%;
  //height: 330px;
  aspect-ratio: 4/3;
  object-fit: cover;
}
/*-------------------------------------------*\
    Windows/Display
\*-------------------------------------------*/

.minimize {
  display: none;
}

.window {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  flex-flow: column;
  display: flex;
  height: auto !important;
  min-width: 40vw;
}

@media only screen and (max-width: 700px) {
  .window {
    width: 75vw !important;
    height: auto !important;
  }
}

.fullscreen {
  width: 100% !important;
  height: var(--fullscreen) !important;
  margin: 0;
  transition: all 0.5s ease;
  padding: 0;
}

.content {
  flex-grow: 1;
  overflow-x: hidden;
  padding-right: var(--content-padding-right);
  padding-left: var(--content-padding-left);
  padding-top: var(--content-padding-top);
  padding-bottom: var(--content-padding-bottom);
}

/*-------------------------------------------*\
    Top Bar
\*-------------------------------------------*/

.top-bar {
  background: rgb(0, 0, 124);
}

.icon-image {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.top-bar-window {
  display: flex;
  width: auto;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  z-index: 10;
  margin: 2px;
  padding: 2px 2px 2px 2px;
}

.top-bar-deactivated {
  background: rgb(123, 125, 123);
}

.top-bar:hover {
  cursor: default;
}

.window-name {
  color: white;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 0;
  font-size: 16px;
  margin: 0 0 0 3px;
}

.icon-image {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  margin-top: 0;
  margin-bottom: 0;
}

video {
  width: 100%;
}

.hidden {
  display: none;
}

.block {
  display: block;
}

.navbar {
  padding: 6px;
  display: flex;
}

.remote {
  height: 28px;
  border-radius: 10px;
  margin-left: 2px;
  margin-right: 2px;
  box-shadow: 1.5px 1.5px black;
  border-top: solid rgb(250, 250, 250) 1.5px;
  border-left: solid rgb(250, 250, 250) 1.5px;
  border-bottom: solid rgb(90, 90, 90) 1.5px;
  border-right: solid rgb(90, 90, 90) 1.5px;
  background: rgb(192, 192, 192);
  border-radius: 0.5px;
  display: flex;
  justify-content: flex-start;
  align-items: center !important;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: bold;
  font-size: 0.7rem;
  padding-top: 2px;
}

.remote:active {
  background: rgb(192, 192, 192);
  box-shadow: none;
  border-top: solid rgb(0, 0, 0) 1.5px;
  border-left: solid rgb(0, 0, 0) 1.5px;
  border-bottom: solid rgb(250, 250, 250) 1.5px;
  border-right: solid rgb(250, 250, 250) 1.5px;
}

.remote:hover {
  cursor: pointer;
}

.flex-col {
  display: flex;
  flex-direction: column;
}
</style>

<script>
import interact from "interactjs";
// import {debounce} from "lodash";
export default {
  props: {
    'windowId': String,
    'nameOfWindow': String,
    content_padding_left: {
      required: false,
      type: String,
      default: '15%',
    },
    content_padding_right: {
      required: false,
      type: String,
      default: '15%'
    },
    content_padding_top: {
      required: false,
      type: String,
      default: '5%'
    },
    content_padding_bottom: {
      required: false,
      type: String,
      default: '5%'
    },
  },
  data: function () {
    return {
      channel: 0,
      channelTemp: 0,
      showStatic: false,
      muted: false,
      // ID Name of window (important)
      ComponentName: this.nameOfWindow,

      // window
      Window: {},
			numbers: Array.from({length: 96}, (_, i) => i + 1),

      dragOption: {
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: "#screen",
            endOnly: true
          })
        ],
        allowFrom: '#top-bar',
      },
      // values for interact.js transformation
      x: 0,
      y: 0,
      tempX: 0,
      tempY: 0,
      w: 400,
      h: 0,

    }
  },
	mounted() {
		this.channel = this.pickRandomNumber()
	},
  computed: {
    style() {
      return {
        height: `${this.h}px`,
        width: `${this.w}px`,
        transform: `translate(${this.x}px, ${this.y}px)`,
        '--content-padding-left': this.content_padding_left || '15%',
        '--content-padding-right': this.content_padding_right || '15%',
        '--content-padding-top': this.content_padding_top || '5%',
        '--content-padding-bottom': this.content_padding_bottom || '5%',
        '--fullscreen': this.$store.getters.getFullscreenWindowHeight
      };
    },
    currentChannel() {
      return 'https://d3sv0vajsmur4z.cloudfront.net/' + this.channel + 'vid.mp4#t=0.1';
    },
  },
  created() {
    this.window = this.$store.getters.getWindowById(this.ComponentName)
  },
  methods: {
    // functions to interact with window state
    removeStatic() {
      this.showStatic = false;
    },

		pickRandomNumber() {
			if (this.numbers.length === 0) {
				this.numbers = Array.from({length: 96}, (_, i) => i + 1)
			}
			const index = Math.floor(Math.random() * this.numbers.length)
			return this.numbers.splice(index, 1)[0]
		},

    nextChannel() {
			this.channel = this.pickRandomNumber()
			this.showStatic = true
			setTimeout(() => {
				this.showStatic = false
        if (this.muted == true)  {
          this.mute()
        }
			}, 2000)
      // debounce(() => {
      //   this.channel = this.channel + 1;
      //   if (this.channel == 96) {
      //     this.channel = 1;
      //   }
      // }, 1000)();
      // const video = document.getElementById("video-player")
      // if (this.channel == 96) {
        // this.channel = 1
        //this.showStatic = true
        // while (video.readyState != 4) {
        //   this.showStatic = true
        // }
        // setTimeout(() => {
          // this.showStatic = false
        // }, 1000)
        // if (video.readyState == 4) {
        //   this.showStatic = false
        // }

      // } else {
        // this.channel++
        // this.showStatic = true
        // while (video.readyState != 4) {
        //   this.showStatic = true
        // }
        //setTimeout(() => {
          //this.showStatic = false
        //}, 1000)
      // }
    },

    mute() {
      const video = document.getElementById("video-player")
      if (video.muted == false) {
        this.muted = true
        video.muted = true
      } else {
        this.muted = false
        video.muted = false
      }
    },

    closeWindow() {
      const payload = {
        'windowState': 'close',
        'windowID': this.ComponentName
      }
      this.$store.commit('setWindowState', payload)
    },

    openWindow() {
      const payload = {
        'windowState': 'open',
        'windowID': this.ComponentName
      }
      this.$store.commit('setWindowState', payload)
    },

    minimizeWindow() {
      const payload = {
        'windowState': 'minimize',
        'windowID': this.ComponentName
      }
      this.$store.commit('setWindowState', payload)
    },

    toggleWindowSize() {
      if (this.$store.getters.getWindowFullscreen(this.ComponentName) == true) {
        const payload = {
          'fullscreen': false,
          'windowID': this.ComponentName
        }
        this.$store.commit('setFullscreen', payload)
        this.x = this.tempX
        this.y = this.tempY

      } else if (this.$store.getters.getWindowFullscreen(this.ComponentName) == false) {
        const payload = {
          'fullscreen': true,
          'windowID': this.ComponentName
        }
        this.$store.commit('setFullscreen', payload)
        const tempX = this.x
        const tempY = this.y
        this.tempX = tempX
        this.tempY = tempY
        this.x = 0
        this.y = 0
      }
    },

    setActiveWindow() {
      this.$store.commit('zIndexIncrement', this.ComponentName)
      this.$store.commit('setActiveWindow', this.ComponentName)
    },

    // drag events

    dragmove(event) {
      this.x += event.dx;
      this.y += event.dy;
      this.setActiveWindow()
    },

    resizemove(event) {
      this.w = event.rect.width;
      this.h = event.rect.height;

      this.x += event.deltaRect.left;
      this.y += event.deltaRect.top;
    }
  }
}
</script>
