<template>
<nav 
    class="grid-container"
    :style="{height: gridHeight}"
>
    <li v-for="window in windows" :key="window.key">
        <button v-if="window.showInNavbar != false" class="icon" @touchstart="openWindow(window.windowId)" @dblclick="openWindow(window.windowId)">
            <img class="icon-image" :src="require('../assets/win95Icons/' + window.iconImage)" :alt="window.altText" />
            <div class="border">
            <p class="icon-text" :class="{'text-black' : [0,3].includes($store.state.backgroundIndex) }">
                {{window.displayName}}
            </p>
            </div>
        </button>
    </li>
    <li>
    </li>
</nav>
</template>

<style scoped>
.icon-text {
    font-size: 12px;
    font-weight: 300;
    color: white;
}

.text-black {
    color: black;
}

.icon-image {
  width: 40px;
  height: auto;
}

.icon:hover {
    cursor: pointer;
}
</style>

<script>
export default {
    name: 'AppGrid',
    data: function () {
        return {
            windows: this.$store.getters.getWindows,
            gridHeight: ''
        }
    },
    methods: {
        openWindow(windowId) {
            const payload = {
                'windowState': 'open',
                'windowID': windowId
            }
            this.$store.commit('setWindowState', payload)
        }
    },
    mounted() {
        let gridH =  this.$store.getters.getFullscreenWindowHeight
        this.gridHeight = gridH.substring(0, gridH.length - 2) - 60 + 'px'
    },
}
</script>
